<template>
  <div class="content-container">
    <PageTitle :title="$t('MEDIAQUIZ.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('MEDIAQUIZ_CREATE.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_CMS.MEDIAQUIZ"/>


    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>
        <Dropdown
          id="questionType"
          :field-title="$t('MEDIAQUIZ_CREATE.QUESTION_TYPE')"
          :dropdown-options="questionTypes"
          :selected-option="questionTypes[0]"
          :value="questionTypes[0]"
          :placeholder="$t('MEDIAQUIZ_CREATE.QUESTION_TYPE')"
          :field-name="'type'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :callback="questionTypeSelected"
          :api-error-text="''"
          class="form-element"
        />


        <div v-if="questionType.id === 1">
          <RichTextField
            :field-title="$t('DIAS.DESCRIPTION')"
            :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
            :input-callback="descriptionInput"
          />

          <DragAndDropInput
            ref="dragAndDropInput"
            :field-title="'Tekst'"
            :on-update="updateDragAndDropContent"
          />
        </div>
        <div v-else-if="questionType.id === 2">
          <RichTextField
            field-title="Vraag"
            :input-callback="selectAnswerInputCallback"
            :placeholder="$t('MEDIAQUIZ_CREATE.QUESTION_PLACEHOLDER')"
            :error-text="descriptionError"
          />
          <AnswerText
            :data="slideData"
            :show-errors="showErrors"
            @data="newData"
            @verify="setValid"
          />
          <p class="text-error">{{ noCorrectError }}</p>
        </div>
        <TagSelector
          ref="tagSelector"
          :field-title="$t('TAGS_PLURAL')"
          :field-name="'tags'"
          :rules="'required'"
          :options="tagData"
          :update-parent="updateTags"
          :error-text="tagError"
          class="tags"
        />

        <Dropdown
          id="status"
          :field-title="$t('MEDIAQUIZ_CREATE.STATUS')"
          :dropdown-options="statusTypes"
          :placeholder="$t('MEDIAQUIZ_CREATE.STATUS')"
          :value="statusTypes[0]"
          :field-name="'status'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :callback="() => {}"
          :api-error-text="''"
          class="form-element"
        />
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button" @click="validateTags">
          {{ $t('MEDIAQUIZ_CREATE.CREATE') }}
        </button>
      </template>

    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import FormWrapper from '@/components/yo-form/FormWrapper'
import AnswerText from '@/components/partials/missions/selectAnswer/AnswerText'
import BackButton from '@/components/elements/BackButton'
import TagSelector from '@/components/yo-form/TagSelector'
import DragAndDropInput from '@/components/elements/missions/DragAndDropInput'
import RichTextField from '../../../components/elements/RichTextEditor/RichTextField'
import { useStore } from 'vuex'
import { CREATE_MEDIAQUIZ_SELECT_ANSWER, CREATE_MEDIAQUIZ_DRAG_AND_DROP, GET_TAGS } from '@/store/modules/cms/actions'
import { ref, computed } from 'vue'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import Dropdown from '@/components/yo-form/Dropdown'
import { useRouter } from 'vue-router'
import { convertDragAndDropInputToAPIFormat } from '../../../utils/helpers/mission/drangAndDropHelper'
import { useI18n } from 'vue-i18n'

export default {
  name: 'MediaQuizCreate',
  components: {
    BackButton,
    FormWrapper,
    PageTitle,
    TagSelector,
    Dropdown,
    DragAndDropInput,
    AnswerText,
    RichTextField
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const tagData = computed(() => store.getters.getTags)
    const slideData = ref({})
    const isValid = ref(true)
    const { t } = useI18n()
    const selectAnswerQuestion = ref('')
    const tagError = ref('')

    const descriptionValue = ref('')

    function descriptionInput(data) {
      descriptionValue.value = data
    }

    const descriptionError = ref('')
    const noCorrectError = ref('')

    const questionTypes = [
      { id: 1, name: 'Drag & Drop' },
      { id: 2, name: 'Selecteer antwoord' }
    ]

    const statusTypes = [
      { id: 0, name: t('CONCEPT') },
      { id: 1, name: t('PUBLISHED') }
    ]

    const dragAndDropInput = ref(null)
    const selectedTags = ref([])
    const questionType = ref(questionTypes[0])

    let dragAndDropData = {}

    getTags()
    function getTags() {
      return store.dispatch(GET_TAGS)
    }

    function updateDragAndDropContent(currentText, stopWords, trickWords) {
      dragAndDropData = convertDragAndDropInputToAPIFormat(currentText, stopWords, trickWords)
    }

    function selectAnswerInputCallback(input) {
      selectAnswerQuestion.value = input
    }

    function updateTags(selected) {
      selectedTags.value = selected
    }


    function newData(data) {
      slideData.value = data
    }

    function setValid(valid) {
      isValid.value = valid
    }

    function questionTypeSelected(newType) {
      if (!newType) {
        return
      }
      questionType.value = newType
    }


    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }

    function validateTags() {
      if (selectedTags.value.length === 0) {
        tagError.value = 'Selecteer minimaal één tag.'
      } else {
        tagError.value = ''
      }

      if (questionType.value.id === 2) {
        if (selectAnswerQuestion.value === '') {
          descriptionError.value = 'Voer de vraag in.'
        } else {
          descriptionError.value = ''
        }
        if (!slideData.value.correct.some(correct => correct === true)) {
          noCorrectError.value = 'Minstens een vraag moet correct zijn.'
        } else {
          noCorrectError.value = ''
        }
      } else {
        dragAndDropInput.value.verify()
      }
    }

    /** Form **/
    function validateData(data) {
      if (tagError.value !== '') {
        return
      }

      if (questionType.value.id === 1 && !dragAndDropInput.value.verify()) {
        return
      }

      const formData = {}

      formData.isActivated = reverseLookup(statusTypes, data.status)

      for (let i = 0; i < selectedTags.value.length; i++) {
        formData[`tags[${i}]`] = selectedTags.value[i]
      }

      if (questionType.value.id === 1) {
        formData.question = dragAndDropData.question
        formData.answers = dragAndDropData.answer
        formData.correctAnswer = dragAndDropData.correctAnswer
        formData.description = descriptionValue.value
        createQuestionDragAndDrop(formData)
      } else if (questionType.value.id === 2) {
        if (descriptionError.value !== '' || noCorrectError.value !== '') {
          return
        }

        formData.question = selectAnswerQuestion.value
        for (let i = 0; i < slideData.value.answers.length; i++) {
          formData[`answers[${i}]`] = slideData.value.answers[i]
        }
        for (let i = 0, j = 0; i < slideData.value.correct.length; i++) {
          if (slideData.value.correct[i]) {
            formData[`correctAnswer[${j}]`] = i + 1
            j++
          }
        }
        createQuestionSelectAnswer(formData)
      }
    }

    function createQuestionSelectAnswer(data) {
      store.dispatch(CREATE_MEDIAQUIZ_SELECT_ANSWER, data).then(result => {
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.MEDIAQUIZ })
        }
      })
    }

    function createQuestionDragAndDrop(data) {
      store.dispatch(CREATE_MEDIAQUIZ_DRAG_AND_DROP, data).then(result => {
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.MEDIAQUIZ })
        }
      })
    }

    return {
      ROUTE_NAMES_CMS,
      tagData,
      updateTags,
      newData,
      setValid,
      slideData,
      selectAnswerInputCallback,
      descriptionError,
      dragAndDropInput,
      descriptionInput,

      questionTypes,
      statusTypes,
      questionTypeSelected,
      validateTags,

      updateDragAndDropContent,
      questionType,
      tagError,
      noCorrectError,

      /** Form **/
      validateData,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.add-answer-button {
  align-items: center;
  color: var(--blue_link);
  display: flex;
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);

  &:hover{
    cursor: pointer;
  }

  .icon {
    height: auto;
    margin-right: rem(14);
    width: rem(20);
  }
}

.tags {
  margin: rem(28) 0;
}

.answer-actions{

  position: absolute;
    display: flex;
    gap: 50px;
    left: rem(550);
    bottom: rem(15);
    flex-wrap: nowrap;
}
</style>
